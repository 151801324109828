<template>
    <div class="product-header" :class="{animate}">
        <MaltImg src="/cms-front/ai-header.svg?vh=b817ac" class="product-header__svg" alt="AI Search Header" width="2000" />
        <ProductLogo class="product-header__logo" :animate="animate" />
    </div>
</template>

<script lang="ts" setup>
    import {ref, onMounted} from 'vue';
    import {MaltImg} from '#components';
    import {useGsap} from '@/composables/useGsap';
    import ProductLogo from './ProductLogo.vue';

    const {ScrollTrigger} = useGsap();
    const animate = ref(false);

    onMounted(() => {
        animate.value = true;

        // show header on scroll
        const headerEl = document.querySelector('header.header');
        ScrollTrigger.create({
            trigger: 'main > section:nth-child(2)',
            start: 'top top',
            onEnter: () => headerEl?.classList.add('animate'),
            onLeaveBack: () => headerEl?.classList.remove('animate'),
        });
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    $ref-width: 1920;

    $logo-width-value: 153.1 * 2;
    $logo-width-max: $logo-width-value * 1px;
    $logo-width: calc($logo-width-value / $ref-width) * 100vw;

    $logo-font-size-value: 46.92;
    $logo-font-size-max: $logo-font-size-value * 1px;
    $logo-font-size: calc($logo-font-size-value / $ref-width) * 100vw;

    .product-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        pointer-events: none;

        &__svg {
            width: 100%;
            height: auto;
            transform: translate3d(0, -50%, 0) scaleX(2);
            transition: transform 1.5s var(--ai-transition-timing-function);

            .animate & {
                transform: translate3d(0, 0, 0) scaleX(1);
            }
        }

        &__logo {
            position: absolute;
            color: white;
            width: clamp(30px, $logo-width, $logo-width-max);
            font-size: clamp(10px, $logo-font-size, $logo-font-size-max);
            filter: brightness(0) saturate(100%) invert(100%);
            right: 2.5vw;
            top: 5vw;

            .animate & {
                img {
                    transition-delay: 0.4s;
                }
                p {
                    transition-delay: 0.6s;
                }
            }
        }
    }
</style>
