<template>
    <ProductHeader />
    <LazySudosuFrame />
    <LazyCountryLaunchingBanner />
    <LazyUnsubscribeInvitationBanner />
    <LazyNavbarUnlogged />
    <main class="relative">
        <slot />
    </main>
    <LazyFooterUnlogged :always-visible="true" />
    <LazyProductFooter />
</template>

<script setup lang="ts">
    import {useHead} from '#imports';
    import {LazySudosuFrame, LazyNavbarUnlogged, LazyFooterUnlogged} from '#components';
    import LazyCountryLaunchingBanner from '~/components/CountryLaunchingBanner.vue';
    import LazyUnsubscribeInvitationBanner from '~/components/UnsubscribeInvitationBanner.vue';
    import ProductHeader from '~/components/product/ProductHeader.vue';
    import LazyProductFooter from '~/components/product/ProductFooter.vue';

    useHead({
        bodyAttrs: {class: `page-product`},
    });
</script>

<style lang="scss">
    @import '~/assets/styles/product';
</style>
